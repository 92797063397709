import React, { useState, useEffect, useRef, useMemo } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { initializeRegisterSteps } from "modules/components";
import Flex from "app/components/Flex";
import Icon, { STEEZYLogo } from "app/components/Icon";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { DATA_TEST_ID } from "constants/index";
import {
  useGetCategoriesQuery,
  useUserPreferenceOptionsQuery,
} from "services/graphql";
import { useFamilyFriendlyFlag } from "hooks/useFeatureFlags";
import { PrivacyFooter } from "app/components/PrivacyFooter";
import { CreateAccount } from "./OnboardingSteps/CreateAccount";
import {
  RESIZE_WIDTHS,
  REGISTER_STEPS_MAP,
  FAMILY_DISABLED_REGISTER_STEPS,
} from "./constants";
import { ContainerWrapper, Container } from "./styles";
import { AccountTypes } from "./OnboardingSteps/AccountTypes";
import { FamilyGoals } from "./OnboardingSteps/FamilyGoals";
import { Levels } from "./OnboardingSteps/Levels";
import { Reasons } from "./OnboardingSteps/Reasons";
import { Categories } from "./OnboardingSteps/Categories";
import { ProgressTrackComponent } from "./ProgressTrackComponent";

function Onboarding() {
  const dispatch = useDispatch();
  const wrapperRef = useRef();

  const [isClickDisabled, toggleIsClickDisabled] = useState(false);
  const [selectedCategorySlugs, setSelectedCategorySlugs] = useState([]);
  const [accountType, setAccountType] = useState(null);

  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();
  const navbarHeight = useSelector(
    ({ components }) => components.Navbar.height
  );
  const onboardingStep = useSelector(
    ({ components }) => components.Register.step
  );
  const initialCategoryFilters = ["isOnboarding"];

  const {
    data: categoriesData,
    refetch: refetchCategories,
    networkStatus: categoriesNetworkStatus,
  } = useGetCategoriesQuery({
    variables: {
      slugs: [],
      filters: initialCategoryFilters,
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: onboardingData,
    loading: onboardingDataLoading,
  } = useUserPreferenceOptionsQuery();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const serverEntrypoint = useSelector(({ server }) => server.entrypoint);
  const isEntryAnimationDisabled = useMemo(() => {
    if (!serverEntrypoint || serverEntrypoint.split("?")[0] !== "/register") {
      return false;
    }
    return isFirstLoad;
  }, [isFirstLoad, serverEntrypoint]);

  useEffect(() => {
    setIsFirstLoad(false);
  }, [setIsFirstLoad]);

  const { categories } = categoriesData || {};

  useEffect(() => {
    if (isFamilyFeatureEnabled) {
      const registerSteps = accountType
        ? REGISTER_STEPS_MAP[accountType]
        : REGISTER_STEPS_MAP.default;

      dispatch(initializeRegisterSteps(registerSteps));
    } else {
      dispatch(initializeRegisterSteps(FAMILY_DISABLED_REGISTER_STEPS));
    }
  }, [isFamilyFeatureEnabled, accountType]);

  if (onboardingDataLoading) {
    return <LoaderCentered />;
  }

  const { accountTypes, familyGoals, levels, reasons } = onboardingData;

  return (
    <>
      <Flex
        flexDirection="column"
        overflow="scroll"
        ref={wrapperRef}
        width="100%"
        pl={{
          _: 0,
          md: 3,
          lg: 5,
        }}
        px={3}
        pb={0}
        mt={4}
        mb={5}
      >
        <Flex
          data-test-id={DATA_TEST_ID.onboarding.wrapper}
          position="relative"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <ContainerWrapper step={onboardingStep}>
            <Container step={onboardingStep} navbarHeight={navbarHeight}>
              <Flex
                width={RESIZE_WIDTHS}
                justifyContent={{
                  _: "center",
                  lg: "space-between",
                }}
              >
                <Icon
                  as={STEEZYLogo}
                  color="black"
                  width="80px"
                  height="16px"
                  mb={{ _: 4, sm: 5 }}
                  mx={{ _: 0, lg: "36px" }}
                />
                <Flex />
              </Flex>
              <Flex
                flexDirection="column"
                position="relative"
                width={RESIZE_WIDTHS}
                px={{ _: 0, lg: 4 }}
              >
                <ProgressTrackComponent accountType={accountType} />
                {onboardingStep === "accountType" && (
                  <AccountTypes
                    accountTypes={accountTypes}
                    setAccountType={setAccountType}
                    isClickDisabled={isClickDisabled}
                    isEntryAnimationDisabled={isEntryAnimationDisabled}
                    toggleIsClickDisabled={toggleIsClickDisabled}
                  />
                )}
                {onboardingStep === "familyGoal" && (
                  <FamilyGoals
                    familyGoals={familyGoals}
                    setAccountType={setAccountType}
                    isClickDisabled={isClickDisabled}
                    toggleIsClickDisabled={toggleIsClickDisabled}
                    accountType={accountType}
                  />
                )}
                {onboardingStep === "level" && (
                  <Levels
                    levels={levels}
                    isClickDisabled={isClickDisabled}
                    toggleIsClickDisabled={toggleIsClickDisabled}
                  />
                )}
                {onboardingStep === "reason" && (
                  <Reasons
                    reasons={reasons}
                    isClickDisabled={isClickDisabled}
                    setSelectedCategorySlugs={setSelectedCategorySlugs}
                    toggleIsClickDisabled={toggleIsClickDisabled}
                    refetchCategories={goal => refetchCategories({ goal })}
                  />
                )}
                {onboardingStep === "categories" &&
                  // This signifies any in-flight network call
                  (categoriesNetworkStatus < 7 ? (
                    <LoaderCentered />
                  ) : (
                    <>
                      <Categories
                        categories={categories}
                        selectedCategorySlugs={selectedCategorySlugs}
                        setSelectedCategorySlugs={setSelectedCategorySlugs}
                        isClickDisabled={isClickDisabled}
                        toggleIsClickDisabled={toggleIsClickDisabled}
                      />
                    </>
                  ))}
                {["email", "password", "checkout"].includes(onboardingStep) && (
                  <CreateAccount />
                )}
              </Flex>
            </Container>
          </ContainerWrapper>
        </Flex>
      </Flex>
      {onboardingStep !== "register" && <PrivacyFooter />}
    </>
  );
}

export default withRouter(Onboarding);
